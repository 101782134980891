<template>
  <div class="clear-search">
    <div class="clear-search__container">
      <SearchCounter
        v-if="!hideCounter"
        :ads-count="adsCount"
        :product-type-name-singular="productTypeNameSingular"
        :product-type-name-plural="productTypeNamePlural"
      />
      <div class="clear-search__button-container">
        <button
          @click.prevent="triggerClear"
          type="reset"
          class="button button--invert clear-search__button"
          :class="{ 'button--disabled': isClearDisabled }"
          :disabled="isClearDisabled"
        >
          Ryd
        </button>
        <button
          @click.prevent.once="eventBus.emit('create-search')"
          type="submit"
          class="button button--highlight clear-search__button"
        >
          Søg
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SearchCounter from '@search/SearchCounter/SearchCounter.vue'

export default {
  data() {
    return {
      isClearDisabled: true,
    }
  },
  components: {
    SearchCounter,
  },
  props: {
    adsCount: { default: 0, type: Number },
    eventBus: { default: null, type: Object },
    children: { default: null, type: Array },
    searchRequest: { default: null, type: Array },
    productTypeNameSingular: { default: '', type: String },
    productTypeNamePlural: { default: '', type: String },
    hideCounter: { default: false, type: Boolean },
  },
  watch: {
    searchRequest: {
      deep: true,
      handler(searchRequest) {
        this.changeInSearchRequest(searchRequest)
      },
    },
  },
  mounted() {
    this.changeInSearchRequest(this.searchRequest)
  },
  methods: {
    changeInSearchRequest(searchRequest) {
      // there will always be one searchRequest item (productType)
      this.isClearDisabled = searchRequest.length === 1
    },
    triggerClear() {
      this.eventBus.emit('clear-search')
    },
  },
}
</script>

<style lang="scss">
@import 'ClearSearch.scss';
</style>
