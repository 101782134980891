<template>
  <div class="distance">
    <label class="sr-only" :for="uid">Indtast postnummer</label>
    <p class="error" v-if="errorMessage">
      <span>{{ errorMessage }}</span>
      <span class="error__attention"></span>
    </p>
    <div class="input input--search distance-zipContainer my-1">
      <input
        maxlength="4"
        class="input--search"
        title="Indtast postnummer"
        type="text"
        :id="uid"
        placeholder="Indtast postnummer"
        @input="zipValue = $event.target.value"
        :value="zipValue"
        @blur="validateZip()"
        ref="zipValueInput"
      />
      <span class="distance-city" v-if="zipValue">{{ cityValue }}</span>
      <ClearInput
        :value="zipValue"
        :event-bus="eventBus"
        :location-icon="true"
      />
    </div>
    <div v-if="cityValue">
      <span class="distance-text">Afstand til sælger</span>
      <List
        :type="'radio'"
        :search-type="searchType"
        :items="items"
        :event-bus="proxyBus"
        :close-on-select="closeOnSelect"
      ></List>
    </div>
  </div>
</template>

<script>
import { app } from '@/main'
import mitt from 'mitt'

import List from '@search/List/List.vue'
import UidMixin from '@shared/uid-mixin.vue'
import ClearInput from '@shared/ClearInput/ClearInput.vue'

export default {
  mixins: [UidMixin],
  name: 'Distance',
  components: {
    List,
    ClearInput,
  },
  props: {
    zip: { default: null, type: Number },
    city: { default: null, type: String },
    latitude: { default: null, type: Number },
    longitude: { default: null, type: Number },
    items: { default: null, type: Array },
    eventBus: { default: null, type: Object },
    searchType: { default: null, type: String },
    id: { default: '', type: String },
    closeOnSelect: { default: false, type: Boolean },
  },
  data() {
    return {
      pristine: false,
      zipValue: '',
      cityValue: '',
      latValue: 0,
      longValue: 0,
      itemValue: -1,
      itemName: '',
      errorMessage: '',
      proxyBus: null,
    }
  },
  watch: {
    zipValue(newValue, oldValue) {
      if (newValue == '') {
        this.errorMessage = ''
        this.validateZip()
      } else if (isNaN(parseFloat(newValue))) {
        this.clearData()
        this.errorMessage = 'Indtast gyldigt postnummer'
      } else if (newValue.toString().length < 4) {
        this.clearData()
        this.errorMessage = ''
        if (oldValue.toString().length === 4) {
          this.emitValues()
        }
      } else if (newValue.toString().length > 4) {
        this.clearData()
        this.errorMessage = 'Indtast gyldigt postnummer'
      } else {
        this.errorMessage = ''
        if (newValue.toString().length === 4) {
          this.validateZip()
        }
      }
    },
  },
  methods: {
    clearData() {
      this.cityValue = ''
      this.latValue = 0
      this.longValue = 0
    },
    clearZip() {
      this.zipValue = ''
      this.validateZip()
      this.$refs.zipValueInput.focus()
    },
    validateZip() {
      if (this.pristine) {
        this.pristine = false
        this.emitValues()
        return
      }
      if (this.zipValue == '') {
        this.clearData()
        this.emitValues()
        return
      }
      if (isNaN(parseFloat(this.zipValue))) {
        this.errorMessage = 'Indtast gyldigt postnummer'
      } else {
        this.errorMessage = ''
        app.axios.get(`/city/location/${this.zipValue}`).then(resp => {
          if (!resp.data.found) {
            this.cityValue = ''
            this.errorMessage = 'Postnummer eksisterer ikke'
          } else {
            this.cityValue = resp.data.city
            this.latValue = resp.data.latitude
            this.longValue = resp.data.longitude
          }

          this.emitValues()
        })
      }
    },
    emitValues() {
      const item = {}
      if (this.latValue != 0 && this.longValue != 0 && this.itemValue != -1) {
        item.value = {
          distance: this.itemValue,
          latitude: this.latValue,
          longitude: this.longValue,
        }
      }

      const summaryOptions = []

      let distanceName
      if (this.itemName !== '' && this.cityValue !== '') {
        distanceName = `${this.cityValue} maks ${this.itemName}`
      }

      summaryOptions.push({
        name: distanceName,
        clearSelf: this.clear,
        type: this.searchType,
      })

      this.eventBus.emit('search-request-callback', {
        value: item.value !== undefined ? item.value : {},
        searchType: this.searchType,
        selected: item.value !== undefined,
        summaryOptions: summaryOptions,
      })
    },
    searchRequestCallback(options) {
      this.itemValue = options.value
      this.itemName = options.value / 1000 + ' km'
      this.emitValues()
    },
    clear() {
      this.clearData()
      this.clearZip()
      this.emitValues()
    },
  },
  created() {
    this.zipValue = this.zip != null ? this.zip.toString() : ''
    this.cityValue = this.city != null ? this.city : ''
    this.latValue = this.latitude != null ? this.latitude : 0
    this.longValue = this.longitude != null ? this.longitude : 0
    if (this.zipValue != '') {
      this.pristine = true
    }

    this.proxyBus = mitt()
    this.proxyBus.on('search-request-callback', this.searchRequestCallback)

    if (this.latValue != 0 && this.longValue != 0 && this.itemValue != -1) {
      this.emitValues()
    }

    this.eventBus.on('clear-input-string', this.clearZip)
  },
}
</script>

<style lang="scss">
@import 'Distance.scss';
</style>
