<template>
  <fieldset class="list">
    <div class="list__inner" ref="listContainer">
      <legend aria-hidden="true">Vælg {{ this.searchType }}</legend>
      <div
        class="list__item-container"
        v-for="(item, index) in items"
        :class="highlight"
        :key="item.name.toString() + index + item.value"
      >
        <ListInput
          v-if="!item.isHeader"
          :change-callback="change"
          :item="item"
          :type="type"
          :searchType="searchType"
          :singleSelect="singleSelect"
          :index="index"
          :proxy-bus="proxyBus"
        />
        <ListTitle v-else-if="!item.isHidden" :title="item.name" />
      </div>
    </div>
  </fieldset>
</template>

<script>
import { app } from '@/main'
import { nextTick } from 'vue'

import ListTitle from './ListTitle.vue'
import ListInput from './ListInput.vue'

import ListPropsMixin from '@search/List/list-props-mixin.vue'
import UidMixin from '@shared/uid-mixin.vue'

import {
  getNextValidIndex,
  getPreviousValidIndex,
  getLastValidIndex,
} from '@/smartList'

export default {
  mixins: [ListPropsMixin, UidMixin],
  name: 'List',
  components: {
    ListInput,
    ListTitle,
  },
  props: {
    type: { default: 'checkbox', type: String },
    searchType: { default: null, type: String },
    id: { default: '', type: String },
    eventBus: { default: null, type: Object },
    proxyBus: { default: null, type: Object },
    items: { default: null, type: Array },
    singleSelect: { default: false, type: Boolean },
    closeOnSelect: { default: false, type: Boolean },
    initialSelection: { default: null, type: Array }, // this property is for ContactForm only and not used anywhere else
    triggerWhenLoaded: { default: true, type: Boolean }, // this is for stopping trigger of criteria callback on load
  },
  data() {
    return {
      highlight: `${this.type} ${this.type}--highlight`,
    }
  },
  created() {
    if (this.proxyBus) {
      this.proxyBus.on('init-smartlist', this.initSmartList)
      this.proxyBus.on('init-smartlist-reverse', this.goToSmartlistReverse)
      this.proxyBus.on('move-smartlist', this.handleSmartListFocus)
    }

    if (this.triggerWhenLoaded) {
      this.items.forEach(item => {
        if (item.value === undefined) return

        if (item.selected) {
          // remove url if exists otherwise we will have a redirect loop because of productType
          item.url = undefined

          this.triggerChanges(true, item, true)
        } else {
          // if item isnt selected but children are
          if (item.children !== undefined) {
            item.children.forEach(child => {
              if (child.selected) {
                this.triggerChanges(true, child, true)
              }
            })
          }
        }
      })
    }
  },
  watch: {
    initialSelection: {
      deep: true,
      handler() {
        this.setInitialSelected()
      },
    },
  },
  methods: {
    ///***** Initiate smartList logics *****///
    initSmartList() {
      this.handleSmartListFocus({ currentIndex: undefined, isDownwards: true })
    },
    ///***** Entering the active smartList from below, focusing the last item in the list (not hidden and not header) *****///
    /// Used by [modelList]
    goToSmartlistReverse() {
      if (!this.proxyBus) return

      let lastIndex = getLastValidIndex(this.items)

      if (lastIndex !== undefined)
        this.proxyBus.emit('focus-smartlist', lastIndex)
    },
    ///***** Facilitating itemFocus og listExit, based on a set of given options and a bit og logic *****///
    handleSmartListFocus(options) {
      if (!this.proxyBus) return

      let index
      let childIndex
      if (options.isDownwards) {
        index = getNextValidIndex(this.items, options.currentIndex)
      } else {
        index = getPreviousValidIndex(this.items, options.currentIndex)
        if (index !== undefined && this.items[index]?.children !== undefined) {
          childIndex = getLastValidIndex(this.items[index].children)
        }
      }

      if (index !== undefined) {
        this.proxyBus.emit('focus-smartlist', {
          index: index,
          childIndex: childIndex,
        })
      } else if (!options.isDownwards) {
        this.proxyBus.emit('exit-smartlist')
      } else if (options.isDownwards) {
        // Used by [modelList], due to the extra 'layer of lists'
        this.proxyBus.emit('exit-smartlist-bottom')
      }
    },
    setInitialSelected() {
      const idList = []
      const owner = this
      this.initialSelection.forEach(item => {
        idList.push(item.value)
      })
      this.items.filter(item => {
        if (idList.includes(item.value)) {
          owner.triggerChanges(true, item)
        }
      })
    },
    clear() {
      this.items.forEach(item => {
        this.clearItem(item)
      })
    },
    clearItem(item) {
      this.triggerChanges(false, item, true)
    },
    change(isChecked, item, parent, useHistoryBack = true) {
      if (item === undefined) return

      item.parent = parent
      this.triggerChanges(isChecked, item, undefined, useHistoryBack)
    },
    triggerChanges(isSelected, item, summaryTriggered, useHistoryBack = true) {
      if (this.singleSelect) {
        this.items.forEach(listItem => {
          listItem.selected = false
          this.emitSearchRequest(listItem)
        })
      }

      item.type = this.type
      item.selected = isSelected

      // find self and all dublicates and check/uncheck them
      const foundDuplicates = this.findDuplicates(item)
      foundDuplicates.forEach(duplicate => {
        duplicate.selected = item.selected

        this.checkChildren(duplicate, item)
      })

      // handle for model-class children
      if (item.parent !== undefined) {
        //  if deselected parent should be deselected
        if (!item.selected) {
          // deselect parent and report to searchRequest
          item.parent.selected = false
          this.emitSearchRequest(item.parent)

          // search for duplicate parents
          const foundParentDuplicates = this.findDuplicates(item.parent)
          foundParentDuplicates.forEach(duplicateParent => {
            duplicateParent.selected = false
          })

          item.parent.children.forEach(child => {
            nextTick(() => {
              this.emitSearchRequest(child)
            })
          })
        }
      }

      this.emitSearchRequest(item)

      // logic if we need to close overlay and go history back on close
      if (this.closeOnSelect && !summaryTriggered && useHistoryBack) {
        if (app.history.getStackSize() != 0) {
          app.history.pop()
        }
      }
    },
    checkChildren(parent, item) {
      // handle for model-class parent - check all children
      // if model-class selectes or deselectes all children should do the same
      if (parent.children !== undefined) {
        parent.children.forEach(child => {
          nextTick(() => {
            // remove all children and tell searchRequest
            this.emitSearchRequest({ ...child, selected: false })

            // then select all children (but don't tell searchRequest)
            child.selected =
              // clearAll comes from clear function when removing from summary
              (item.clearAll !== undefined && !item.clearAll) || item.selected
          })
        })
      }
    },
    emitSearchRequest(item) {
      // find duplicates with potiental children
      const duplicates = this.findDuplicates(item)
      duplicates.forEach(duplicate => {
        if (duplicate.children !== undefined) {
          item.parent = duplicate
        }
      })

      const callbackValue = {
        value: item.value,
        searchType: item.searchType ? item.searchType : this.searchType,
        selected: item.selected,
        summaryOptions: [
          {
            name: item.name,
            value: item.value,
            clearSelf: () => {
              // tell clearItem to clear all children
              item.clearAll = true
              this.clearItem(item)
            },
          },
        ],
      }

      if (item.url !== undefined) {
        callbackValue.url = item.url
      }

      if (item.searchValue !== undefined) {
        callbackValue.searchValue = item.searchValue
      }

      // report changes to search-request with potiental children for clearSelf
      this.eventBus.emit('search-request-callback', callbackValue)
    },
    findDuplicates(item) {
      return this.items.filter(
        x => x.name === item.name && x.value === item.value
      )
    },
  },
}
</script>

<style lang="scss">
@import 'List.scss';
@import 'ListIcons.scss';
</style>
