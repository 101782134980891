<template>
  <div class="advanced-search" :class="{ 'advanced-search--open': isOpen }">
    <div class="advanced-search__close-button-container">
      <button
        v-if="isOpen"
        @click.prevent="eventBus.emit('close-advanced-search')"
        type="button"
        class="advanced-search__close-button"
      >
        <span class="advanced-search__close-button-text">
          Luk udvidet søgning
        </span>
        <span class="icon--plus icon--rotate"></span>
      </button>
    </div>
    <SearchCounter
      v-if="!isOpen"
      :ads-count="adsCount"
      :product-type-name-singular="productTypeNameSingular"
      :product-type-name-plural="productTypeNamePlural"
    />
    <div
      v-if="!isOpen"
      class="advanced-search__button-container overlay-master"
    >
      <button
        @click.prevent="eventBus.emit('open-advanced-search')"
        type="button"
        class="button button--invert advanced-search__button"
      >
        Udvidet søgning
      </button>
      <button
        @click="eventBus.emit('create-search')"
        type="button"
        class="button button--highlight advanced-search__button"
      >
        Søg
      </button>
    </div>
    <template v-if="isOpen">
      <component
        v-for="component in children"
        v-bind="component.props"
        :key="component.id"
        :is="component.type"
        :id="component.id"
        :catch-search-type="component.catchSearchType"
        :event-bus="eventBus"
        :product-type-id="productTypeId"
        :search-request="searchRequest"
        :ads-count="adsCount"
        :search-types="component.searchTypes"
      ></component>

      <ClearSearch
        class="clear-search--shadowless"
        :event-bus="eventBus"
        :children="children"
        :ads-count="adsCount"
        :search-request="searchRequest"
        :product-type-name-singular="productTypeNameSingular"
        :product-type-name-plural="productTypeNamePlural"
      ></ClearSearch>
    </template>
  </div>
</template>

<script>
import ClearSearch from '@search/ClearSearch/ClearSearch.vue'
import SearchCounter from '@search/SearchCounter/SearchCounter.vue'

export default {
  name: 'AdvancedSearch',
  components: {
    ClearSearch,
    SearchCounter,
  },
  props: {
    title: { default: '', type: String },
    children: { default: null, type: Array },
    eventBus: { default: null, type: Object },
    productTypeId: { default: null, type: Object },
    isOpen: { default: null, type: Boolean },
    searchRequest: { default: null, type: Array },
    adsCount: { default: 0, type: Number },
    productTypeNameSingular: { default: '', type: String },
    productTypeNamePlural: { default: '', type: String },
  },
}
</script>

<style lang="scss">
@import 'AdvancedSearch.scss';
</style>
