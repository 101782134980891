<template>
  <div class="autocomplete">
    <ol
      v-if="showSuggests && autocompleteSuggests.length > 0"
      class="autocomplete__container"
    >
      <li
        v-for="(autoCompleteSuggest, index) in autocompleteSuggests"
        :key="'autocomplete' + index"
        :class="{ 'autocomplete__item--active': index === this.focusIndex }"
        class="autocomplete__item"
        @click="handleSearch(autoCompleteSuggest)"
      >
        <span>
          <span v-html="autoCompleteSuggest.name"></span>
          <span
            v-if="autoCompleteSuggest.category"
            class="autocomplete__item-category"
            >i {{ autoCompleteSuggest.category }}</span
          >
        </span>

        <span
          v-if="autoCompleteSuggest.adCount"
          class="autocomplete__item-count"
          >({{ autoCompleteSuggest.adCount }})</span
        >
      </li>
    </ol>
  </div>
</template>

<script>
import { app } from '@/main'

import { highlightTextInString } from '@/functions'

export default {
  data() {
    return {
      autocompleteSuggests: [],
      focusIndex: -1,
      showSuggests: false,
    }
  },
  props: {
    value: { default: '', type: String },
    productTypeId: { default: undefined, type: Object },
    handleSearch: { default: undefined, type: Function },
  },
  watch: {
    value: {
      handler(value) {
        if (value.length > 1) {
          this.getSuggests()
        } else {
          this.showSuggests = false
          this.autocompleteSuggests = []
        }
      },
    },
    showSuggests: {
      handler(value) {
        // if showSuggests is closed - reset focusIndex
        if (value) {
          this.focusIndex = -1
        }
      },
    },
  },
  methods: {
    getSuggests() {
      app.axios
        .post(`/api/autocomplete/autocomplete`, {
          productTypeId: this.productTypeId.value,
          search: this.value,
        })
        .then(resp => {
          let autocompleteSuggests = resp.data

          autocompleteSuggests.forEach(autocompleteSuggest => {
            autocompleteSuggest.name = highlightTextInString(
              autocompleteSuggest.name,
              this.value
            )
          })

          this.autocompleteSuggests = autocompleteSuggests

          if (autocompleteSuggests === 0) return

          this.showSuggests = true
        })
    },
    focusFirst() {
      this.focusIndex = -1
      this.focusNext()
    },
    focusNext() {
      if (this.focusIndex == this.autocompleteSuggests.length - 1) {
        this.focusIndex = -1
      } else {
        this.focusIndex++
      }
    },
    focusPrevious() {
      if (this.focusIndex == 0) {
        this.focusIndex = this.autocompleteSuggests.length
      } else if (this.focusIndex === -1) {
        this.focusIndex = this.autocompleteSuggests.length - 1
      } else {
        this.focusIndex--
      }
    },
    blur() {
      setTimeout(() => {
        this.showSuggests = false
      }, 150)
    },
    focus() {
      if (this.autocompleteSuggests.length > 0) {
        this.showSuggests = true
      }
    },
  },
}
</script>

<style lang="scss">
@import 'AutoComplete.scss';
</style>
