<template>
  <fieldset>
    <div class="list-horizontal">
      <legend aria-hidden="true"></legend>
      <div
        class="list-horizontal__item-container"
        :class="[highlight, { selected: item.selected }]"
        v-for="(item, index) in items"
        :key="index + item.value + item.name"
      >
        <input
          aria-hidden="true"
          @change="change($event, item)"
          :checked="item.selected"
          :type="type"
          :name="searchType + uid"
          :id="searchType + uid + index"
          :value="item.value"
          :data-group="searchType"
          :class="[singleSelect ? 'js-check-group' : '']"
        />
        <label
          :class="[
            item.iconUrl
              ? 'list-horizontal__label--with-icon'
              : 'list-horizontal__label',
          ]"
          :for="searchType + uid + index"
        >
          <span
            :class="[
              item.iconUrl
                ? 'list-horizontal__row--with-icon'
                : 'list-horizontal__row',
            ]"
          >
            <div v-if="item.iconUrl">
              <img
                loading="lazy"
                class="list-horizontal__icon"
                :src="item.iconUrl"
                :alt="item.name"
              />
            </div>
            <div class="list-horizontal__name">{{ item.name }}</div>
          </span>
          <span class="input-visual"></span>
        </label>
      </div>
    </div>
  </fieldset>
</template>

<script>
import ListPropsMixin from '@search/List/list-props-mixin.vue'
import UidMixin from '@shared/uid-mixin.vue'

export default {
  mixins: [ListPropsMixin, UidMixin],
  name: 'ListHorizontal',
  // included to satisfy typescript
  props: {
    type: { default: 'radio', type: String },
    searchType: { default: null, type: String },
    id: { default: '', type: String },
    eventBus: { default: null, type: Object },
    items: { default: null, type: Array },
    singleSelect: { default: false, type: Boolean },
  },
  data() {
    return {
      highlight: `${this.type} ${this.type}--highlight`,
    }
  },
  created() {
    this.items.forEach(item => {
      if (item.selected !== true) return

      this.eventBus.emit('search-request-callback', {
        value: item.value,
        searchType: item.searchType ? item.searchType : this.searchType,
        selected: item.selected,
        url: item.url,
        summaryOptions: [
          { name: item.name, value: item.value, clearSelf: this.clearItem },
        ],
      })
    })

    if (this.eventBus === null) return
  },
  methods: {
    clear() {
      this.items.forEach(item => {
        item.selected = false
        this.eventBus.emit('search-request-callback', {
          value: item.value,
          searchType: item.searchType ? item.searchType : this.searchType,
          selected: item.selected,
          url: item.url,
          summaryOptions: [
            { name: item.name, value: item.value, clearSelf: this.clearItem },
          ],
        })
      })
    },
    clearItem(item) {
      this.triggerChanges({ ...item, value: item.value }, false)
    },
    change(event, item) {
      const eventTarget = event.target

      this.triggerChanges(item, eventTarget.checked)
    },
    triggerChanges(item, newValue) {
      if (newValue) {
        if (this.type == 'radio' || this.singleSelect) {
          this.clear()
        }
      }

      item.type = this.type
      item.selected = newValue

      this.eventBus.emit('search-request-callback', {
        value: item.value,
        searchType: item.searchType ? item.searchType : this.searchType,
        selected: item.selected,
        url: item.url,
        summaryOptions: [
          { name: item.name, value: item.value, clearSelf: this.clearItem },
        ],
      })
    },
  },
}
</script>

<style lang="scss">
@import 'ListHorizontal.scss';
@import '@search/List/ListIcons.scss';
</style>
