<template>
  <div>
    <div v-if="!item.isHidden" class="list__input-container">
      <input
        aria-hidden="true"
        ref="input"
        @change="onChange($event, item)"
        @keydown.space.prevent="onSpacePressed"
        @keydown.enter.prevent="onEnterPressed"
        @keydown.left.prevent="goToParent()"
        @keydown.down.prevent="goToNext()"
        @keydown.up.prevent="goToPrevious()"
        :checked="item.selected"
        :type="type"
        :name="searchType"
        :id="searchType + item.value + '' + uid + index"
        :value="item.value"
        :data-group="searchType"
        :class="[singleSelect ? 'js-check-group' : '']"
      />
      <label
        class="list__label"
        :for="searchType + item.value + '' + uid + index"
      >
        <span class="list__row">
          <div v-if="item.iconUrl">
            <img
              loading="lazy"
              class="list__icon"
              :src="item.iconUrl"
              :alt="item.name"
            />
          </div>
          <span v-html="getName(item)"></span>
        </span>
        <span class="input-visual"></span>
      </label>
    </div>
    <div
      v-if="
        item.isHidden &&
        item.children !== undefined &&
        item.children.find(child => !child.isHidden) !== undefined
      "
      class="list__Label"
    >
      <span v-html="item.name"></span>
    </div>
    <div v-if="item.children">
      <div
        class="list__item-container"
        v-for="(child, index) in item.children.filter(
          x => x.props === undefined
        )"
        :key="'model-class' + index + uid + child.value"
      >
        <div v-if="!child.isHidden" class="list__input-container">
          <input
            aria-hidden="true"
            :ref="'input_child_' + index"
            @change="onChange($event, child, item)"
            @keydown.space.prevent="onSpacePressed"
            @keydown.enter.prevent="onEnterPressed"
            @keydown.left.prevent="goToParent(index)"
            @keydown.down.prevent="goToNext(index)"
            @keydown.up.prevent="goToPrevious(index)"
            :checked="child.selected"
            :type="type"
            :name="searchType"
            :id="searchType + child.value + '' + uid + index"
            :value="child.value"
            :data-group="searchType"
            :class="[singleSelect ? 'js-check-group' : '']"
          />
          <label
            class="list__label list__label--inner"
            :for="searchType + child.value + '' + uid + index"
          >
            <span class="list__row">
              <div v-if="child.iconUrl">
                <img
                  loading="lazy"
                  class="list__icon"
                  :src="child.iconUrl"
                  :alt="child.name"
                />
              </div>
              <span v-html="getName(child)"></span>
            </span>
            <span class="input-visual"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UidMixin from '@shared/uid-mixin.vue'

import {
  getNextValidIndex,
  getPreviousValidIndex,
  getFirstValidIndex,
  //getLastValidIndex,
} from '@/smartList'

export default {
  mixins: [UidMixin],
  name: 'ListInput',
  props: {
    item: {
      default: () => {
        return {}
      },
      type: Object,
    },
    type: { default: 'checkbox', type: String },
    searchType: { default: null, type: String },
    singleSelect: { default: false, type: Boolean },
    index: { default: 0, type: Number },
    proxyBus: { default: null, type: Object },
    changeCallback: { default: null, type: Function },
  },
  data() {
    return {
      changedBySpace: false,
    }
  },
  methods: {
    setFocusHandler(options) {
      this.setFocus(options.index, options.childIndex)
    },
    ///***** Setting browser focus on a specific listInput, where props.index match the incomming index *****///
    setFocus(index, childIndex) {
      if (this.index !== index) return

      if (childIndex !== undefined) {
        const child = this.$refs['input_child_' + childIndex]
        if (child === undefined) return

        child.focus()
        return
      }

      this.$refs.input.focus()
    },
    goToParent(currentChildIndex) {
      if (currentChildIndex !== undefined) {
        this.setFocus(this.index)
        return
      }
      this.proxyBus.emit('move-smartlist', {
        currentIndex: 0,
        isDownwards: false,
      })
    },
    ///***** Triggered when arrow down is pressed, while listInput is active *****///
    /// Emitting currentIndex and arrow direction to parent through proxybus
    /// Parent will find out whether to trigger focus the next listInput item, or leave the list through the bottom
    goToNext(currentChildIndex) {
      if (currentChildIndex !== undefined) {
        const childIndex = getNextValidIndex(
          this.item.children,
          currentChildIndex
        )
        if (childIndex !== undefined) {
          this.setFocus(this.index, childIndex)
          return
        }
      } else if (this.item.children !== undefined) {
        const childIndex = getFirstValidIndex(this.item.children)
        if (childIndex !== undefined) {
          this.setFocus(this.index, childIndex)
          return
        }
      }
      if (!this.proxyBus) return

      this.proxyBus.emit('move-smartlist', {
        currentIndex: this.index,
        isDownwards: true,
      })
    },
    ///***** Triggered when arrow up is pressed, while listInput is active *****///
    /// Emitting currentIndex and arrow direction to parent through proxybus
    /// Parent will find out whether to trigger focus the previous listInput item, or leave the list through the top
    goToPrevious(currentChildIndex) {
      if (currentChildIndex !== undefined) {
        const childIndex = getPreviousValidIndex(
          this.item.children,
          currentChildIndex
        )
        if (childIndex !== undefined) {
          this.setFocus(this.index, childIndex)
          return
        }
        this.setFocus(this.index)
        return
      }

      if (!this.proxyBus) return

      this.proxyBus.emit('move-smartlist', {
        currentIndex: this.index,
        isDownwards: false,
      })
    },
    selectByEvent(event) {
      event.target.click()
    },
    onSpacePressed(event) {
      this.changedBySpace = true

      this.selectByEvent(event)
    },
    onEnterPressed(event) {
      this.selectByEvent(event)
    },
    onChange(event, item, parent) {
      let useHistoryBack = true
      if (this.changedBySpace) {
        this.changedBySpace = false
        useHistoryBack = false
      }

      this.changeCallback(event.target.checked, item, parent, useHistoryBack)
    },
    getName(item) {
      return item.nameMatch ?? item.name
    },
  },
  beforeUnmount() {
    if (this.proxyBus) {
      this.proxyBus.off('focus-smartlist', this.setFocusHandler)
    }
  },
  created() {
    if (this.proxyBus) {
      this.proxyBus.on('focus-smartlist', this.setFocusHandler)
    }
    window.addEventListener('pageshow', () => {
      // fix when bfcache remembers inputs as checked
      // where the data is out of sync because it is not set
      if (!this.item.selected && this.$refs.input) {
        this.$refs.input.checked = false
      }
    })
  },
}
</script>
