<template>
  <div class="model-list">
    <div
      class="model-list__container"
      v-for="(make, index) in makes"
      :key="index"
    >
      <input
        aria-hidden="true"
        type="radio"
        :ref="'model__container' + uid + index"
        :id="make.name + '__container' + uid + index"
        :name="make.name + '__container'"
        @keydown.up.prevent="keyUp(index)"
        @keydown.down.prevent="keyDown(make, index)"
        @keydown.right.prevent="openTab(make)"
        @keydown.left.prevent="closeTab(make, index)"
        @keydown.space.prevent="changeOpen(makes, make, true)"
      />
      <label
        class="model-list__tab"
        :class="{ 'model-list__tab--selected': make.selected }"
        :for="make.name"
        @click="changeOpen(makes, make)"
      >
        <div class="model-list__text-container">
          <p class="model-list__text" v-html="make.name"></p>
          <div
            class="model-list__text-container--inner"
            v-if="hasSelectedChilds(make)"
          >
            <p class="search-indicator__text">({{ make.selectedCount }})</p>
            <span class="search-indicator"></span>
          </div>
        </div>
        <span
          class="icon--plus"
          :class="{ 'icon--rotate': make.selected }"
        ></span>
      </label>

      <div class="model-list__tab-content" :class="{ hidden: !make.selected }">
        <SearchList
          :items="make.items"
          :search-type="searchType"
          :type="type"
          :proxy-bus="internalBus"
          :event-bus="eventBus"
          :id="id"
          :close-on-select="closeOnSelect"
          :is-open="isOpen && make.selected"
        ></SearchList>
      </div>
    </div>
  </div>
</template>

<script>
import { app } from '@/main'

import SearchList from '@search/SearchList/SearchList.vue'
import ListPropsMixin from '@search/List/list-props-mixin.vue'
import UidMixin from '@shared/uid-mixin.vue'

import mitt from 'mitt'

export default {
  name: 'ModelList',
  data() {
    return {
      makes: [],
      internalBus: mitt(),
    }
  },
  mixins: [ListPropsMixin, UidMixin],
  props: {
    catchSearchType: { default: null, type: String },
    type: { default: 'checkbox', type: String },
    searchType: { default: null, type: String },
    id: { default: '', type: String },
    eventBus: { default: null, type: Object },
    proxyBus: { default: null, type: Object },
    items: { default: null, type: Array },
    productTypeId: { default: null, type: Object },
    closeOnSelect: { default: false, type: Boolean },
    isOpen: { default: false, type: Boolean },
  },
  components: {
    SearchList,
  },
  watch: {
    items: {
      deep: true,
      handler(newValue) {
        this.makes = newValue
      },
    },
  },
  methods: {
    ///***** Triggering focus on the first ModelListItem *****///
    initSmartList() {
      this.focusInput(0)
    },
    ///***** Setting focus on a ModelListItem by index *****///
    focusInput(index) {
      if (index === undefined) return

      const makeInput = this.$refs['model__container' + this.uid + index]
      if (!makeInput) return
      setTimeout(() => {
        makeInput.focus()
      }, 50)
    },
    ///***** Helper function - Finding the index of a ModelListItem, based on the make.value *****///
    getMakeIndex(makeValue) {
      let currentIndex
      this.makes.forEach((item, index) => {
        if (item.value !== makeValue) return
        currentIndex = index
      })

      return currentIndex
    },
    ///***** ModelListItem arrow up trigger - finding out whether to: *****///
    /// - Exit the smartlist when index is 0 (this list isn't searchable, which is the reason for a headcoded first valid index)
    /// - Init the smartlist on nested searchList for the above laying ModelListItem if it is selected (active)
    /// - Simply focusing the above laying ModelListItem
    keyUp(index) {
      if (index === 0) {
        if (!this.proxyBus) return
        this.proxyBus.emit('exit-smartlist')
        return
      }

      let makeAbove = this.makes[index - 1]
      if (!makeAbove) return
      if (makeAbove.selected) {
        if (!this.internalBus) return
        this.internalBus.emit('init-smartlist-reverse')

        return
      }
      this.focusInput(index - 1)
    },
    ///***** ModelListItem arrow down trigger - finding out whether to: *****///
    /// - Init the underlaying smartlist if the ModelListItem is selected (active / open)
    /// - Simply focusing the below laying ModelListItem
    keyDown(make, index) {
      if (make.selected) {
        if (!this.internalBus) return
        this.internalBus.emit('init-smartlist')
      } else {
        if (index + 1 === this.makes.length) return

        this.focusInput(index + 1)
      }
    },
    ///***** ModelListItem arrow right trigger - opening the underlaying list, if not already open *****///
    openTab(make) {
      if (make.selected) return

      this.changeOpen(this.makes, make, true)
    },
    ///***** ModelListItem arrow left trigger - closing the underlaying list, if not already closed *****///
    /// When closing the tab, the default result is lost focus. To combat that, we have a delayed focusInput on the index from the triggered ModelListItem
    closeTab(make, index) {
      if (!make.selected) return

      make.selected = false

      this.focusInput(index)
    },
    ///***** Exitting an underlaying smartlist will trigger this, to focus the related ModelListItem *****///
    goToParent() {
      let currentIndex
      this.items.forEach((item, index) => {
        if (!item.selected) return
        currentIndex = index
      })
      if (currentIndex === undefined) return
      this.focusInput(currentIndex)
    },
    ///***** Triggering nextItem on the last item of a smartList within a [ModelList] will result in focus on the ModelListItem below the active one *****///
    goToNextParent() {
      let currentParentFound = false
      let nextParentIndex
      this.items.forEach((item, index) => {
        if (nextParentIndex !== undefined) return
        if (item.isHeader || item.isHidden) return
        if (!item.selected && !currentParentFound) return

        if (item.selected) {
          currentParentFound = true
          return
        }

        nextParentIndex = index
      })

      if (nextParentIndex === undefined) return

      this.focusInput(nextParentIndex)
    },
    makeChange(options) {
      if (this.catchSearchType === options.searchType) {
        if (options.selected) {
          const url = `
            /criteria/models/${this.productTypeId.value}/${options.value}
          `
          // get new data
          app.axios.get(url).then(resp => {
            const make = {
              items: resp.data.items,
              name: resp.data.name,
              value: resp.data.value,
            }

            // is only one make - open it
            if (this.makes.length === 0) {
              make.selected = true
            } else {
              // or deselected all makes
              this.makes.forEach(make => {
                make.selected = false
              })
            }

            this.makes.push(make)
          })
        } else {
          // unselect old data (model in make that has been unselected)
          const findIndex = this.makes.findIndex(
            make => make.value === options.value
          )

          if (findIndex === -1) return

          this.makes[findIndex].items.forEach(item => {
            item.selected = false

            this.eventBus.emit('search-request-callback', {
              value: item.value,
              searchType: item.searchType ? item.searchType : this.searchType,
              selected: item.selected,
              summaryOptions: [{ name: item.name, value: item.value }],
            })

            // deselect and report to searchRequest for all children if found
            if (item.children !== undefined) {
              item.children.forEach(child => {
                child.selected = false

                this.eventBus.emit('search-request-callback', {
                  value: child.value,
                  searchType: child.searchType
                    ? child.searchType
                    : this.searchType,
                  selected: child.selected,
                  summaryOptions: [{ name: child.name, value: child.value }],
                })
              })
            }
          })
          this.makes.splice(findIndex, 1)
        }
      }
    },
    changeOpen(makes, make, maintainFocus = false) {
      const preValue = make.selected

      makes.forEach(value => {
        value.selected = false
      })

      make.selected = !preValue

      if (!maintainFocus) return
      this.focusInput(this.getMakeIndex(make.value))
    },
    hasSelectedChilds(make) {
      let uniqueSelectedItemIds = this.getUniqueSelectItemIds(make.items)

      make.items.forEach(item => {
        if (item.selected) return
        if (item.children === undefined) return

        uniqueSelectedItemIds = [].concat(
          uniqueSelectedItemIds,
          this.getUniqueSelectItemIds(item.children)
        )
      })

      make.selectedCount = uniqueSelectedItemIds.length
      return make.selectedCount > 0
    },
    getUniqueSelectItemIds(items) {
      // filter - finder de items, der er selected
      // map - henter value (id) ud, så vi ikke står med resten af objekterne
      // filter - sorterer dubletter fra, grundet "populære"
      return items
        .filter(item => item.selected)
        .map(item => {
          return item.value
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index
        })
    },
  },
  created() {
    this.eventBus.on('search-request-callback', this.makeChange)
    this.makes = this.items

    if (this.proxyBus) {
      this.proxyBus.on('init-smartlist', this.initSmartList)
    }
    if (this.internalBus) {
      this.internalBus.on('exit-smartlist', this.goToParent)
      this.internalBus.on('exit-smartlist-bottom', this.goToNextParent)
    }
  },
  beforeUnmount() {
    if (this.proxyBus) {
      this.proxyBus.off('init-smartlist', this.initSmartList)
    }
    if (this.internalBus) {
      this.internalBus.off('exit-smartlist', this.goToParent)
      this.internalBus.off('exit-smartlist-bottom', this.goToNextParent)
    }
  },
}
</script>

<style lang="scss">
@import 'ModelList.scss';
</style>
