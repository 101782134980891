<template>
  <div class="list__title-container">
    <div class="list__label">
      <span class="list__row">
        <b v-html="title"></b>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
      type: String,
    },
  },
}
</script>
