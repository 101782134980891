<script>
export default {
  props: {
    type: { default: 'checkbox', type: String },
    searchType: { default: null, type: String },
    items: { default: null, type: Array },
    eventBus: { default: null, type: Object },
  },
}
</script>
