<template>
  <div class="list-horizontal-container">
    <component
      v-for="component in children"
      v-bind="component.props"
      :key="component.id"
      :is="component.type"
      :event-bus="eventBus"
      :search-request="searchRequest"
    ></component>
  </div>
</template>

<script>
import ListHorizontal from '@search/ListHorizontal/ListHorizontal.vue'

import UidMixin from '@shared/uid-mixin.vue'

export default {
  mixins: [UidMixin],
  data() {
    return {
      isDisabled: false,
      isOpen: false,
      isChildOpen: false,
    }
  },
  components: {
    ListHorizontal,
  },
  props: {
    children: { default: null, type: Array },
    eventBus: { default: null, type: Object },
    searchRequest: { default: null, type: Array },
  },
}
</script>

<style lang="scss">
@import 'ListHorizontalContainer.scss';
</style>
